<template>
  <div>
    <v-combobox
      v-model="newValue"
      :hide-no-data="!search"
      :items="items"
      :open-on-clear="false"
      :loading="loading"
      :search-input.sync="search"
      :multiple="multiple"
      :rules="[requiredRule]"
      append-icon=""
      auto-select-first
      clearable
      item-text="name"
      item-value="id"
      :label="label"
      no-data-text="Sorry, no results"
      no-filter
      outlined
      prepend-icon="mdi-account"
      placeholder="Start typing to search"
      return-object
    >
      <template v-slot:no-data>
        <span class="pa-2"> No results </span>
      </template>
      <template v-slot:item="{ attrs, item }">
        <span class="pr-2">
          {{ item.name }}
          <br />
          <small class="grey--text">{{ item.id }}</small>
        </span>
      </template>

      <template v-slot:selection="{ attrs, item }">
        <div v-if="item.id" :class="multiple ? 'pr-5 pb-4' : ''">
          {{ item.name }}
          <br />
          <small class="grey--text">{{ item.id }}</small>
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { doSearch } from "@/services/searchQueries";

export default {
  props: {
    value: {
      required: true,
      default() {
        if (this.multiple) {
          return [];
        } else {
          return {};
        }
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: "Search for advisor",
    },
  },
  data() {
    return {
      search: null,
      items: [],
      selectedAdvisors: [],
      loading: false,
    };
  },
  computed: {
    newValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  watch: {
    search(val) {
      if (this.loading) return;

      this.handleSearch(val);
    },
    value() {
      this.$emit("input", this.value);
    },
  },
  methods: {
    requiredRule(value) {
      if (!this.required) {
        return true;
      }
      if (value instanceof Array && value.length == 0) {
        return "This field is required.";
      }
      return !!value || "Required.";
    },
    async handleSearch(searchKey) {
      this.loading = true;

      if (!searchKey) {
        this.loading = false;
        return;
      }

      let variables = {
        limit: 10,
        filter: { email: { exists: true } },
        sort: [
          {
            direction: "desc",
          },
        ],
      };

      variables.filter = {
        or: [
          { id: { matchPhrasePrefix: searchKey } },
          { email: { matchPhrasePrefix: searchKey } },
          { firstName: { matchPhrasePrefix: searchKey } },
          { lastName: { matchPhrasePrefix: searchKey } },
          { phone: { matchPhrasePrefix: searchKey } },
          { companyName: { matchPhrasePrefix: searchKey } },
        ],
      };

      try {
        const result = await doSearch("advisors", variables);

        this.items = result.items.map((a) => {
          return {
            name: `${a.lastName}, ${a.firstName}`,
            id: a.id,
          };
        });
      } catch (error) {
        console.log("Error searching for advisors: ", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
