<template>
  <div>
    <header class="mb-12">
      <h1 class="mt-5">Reporting</h1>
      <p class="red--text darken-4">PLANGAP PERSONNEL ONLY!</p>
    </header>

    <v-card outlined elevation="12" max-width="700" class="mx-auto">
      <v-card-title>Custom Data Reporting</v-card-title>
      <v-card-text
        >A CSV file will be emailed to the provided email addresses. Emails may
        only be sent to valid <code>plangap.com</code> and
        <code>riskscore.us</code> domains.
        <strong>All fields are required.</strong></v-card-text
      >
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="valid" class="mt-5">
          <v-select
            v-model="reporting.reportType"
            :items="reportTypeItems"
            :rules="rules.required"
            outlined
            prepend-icon="mdi-file-chart-outline"
            label="Report Type"
            dense
            class="mb-4"
          ></v-select>

          <v-select
            v-model="reporting.dateRangeType"
            :items="reportDateRanges"
            :rules="rules.required"
            outlined
            prepend-icon="mdi-calendar-month"
            label="Date Range"
            dense
            class="mb-4"
          ></v-select>

          <v-row v-if="reporting.dateRangeType === 'Custom'" class="mb-4">
            <v-col cols="12" sm="6" class="d-flex justify-center">
              <v-date-picker
                v-model="reporting.reportCustomDateRange"
                :min="minCustomDate"
                :max="maxCustomDate"
                range
                show-current
                show-adjacent-months
                no-title
                color="primary"
              ></v-date-picker>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-center px-8">
              <v-text-field
                :value="customDateRangeText"
                :rules="[handleDatePickerChange]"
                label="Custom Date range"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- avisors:
          <pre>{{ reportSelectedAdvisors }}</pre> -->
          <SearchSelectAdvisor
            v-model="reportSelectedAdvisors"
            :required="false"
          />

          <v-select
            v-model="reporting.filters"
            :items="reportFiltersItems"
            :rules="rules.requiredArray"
            :return-object="true"
            item-text="text"
            item-value="value"
            @change="handleFiltersChange"
            required
            outlined
            multiple
            prepend-icon="mdi-filter-variant"
            label="Filters"
            dense
            class="mb-4"
          >
            ></v-select
          >

          <v-combobox
            v-model="reportingEmails"
            :rules="[handleEmailRules]"
            chips
            label="Recipient Emails"
            multiple
            prepend-icon="mdi-email-plus-outline"
            outlined
            hint="Add email addresses one at a time. Tab or enter to commit email address."
            persistent-hint
            append-icon=""
          >
            <template v-slot:selection="{ attrs, item }">
              <v-chip
                v-bind="attrs"
                close
                :color="item.color"
                @click:close="handleRemoveEmail(item)"
              >
                <strong>{{ item.text }}</strong>
              </v-chip>
            </template>
          </v-combobox>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-6 justify-center"
        ><v-btn text color="error" @click="handleReset" class="mr-4"
          >Clear</v-btn
        >
        <v-btn
          :disabled="!valid"
          @click.stop="showConfirmDialog = true"
          color="primary"
          >Send Report</v-btn
        ></v-card-actions
      >
    </v-card>

    <v-dialog v-model="showSuccessDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="mb-4 text-h5 white--text success">
          Report has been sent!
        </v-card-title>
        <v-card-text>
          <h4>What would you like to do now?</h4>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="showSuccessDialog = false"
            class="mr-4"
          >
            Run a new report
          </v-btn>

          <v-btn color="secondary" to="/admin"> Go to Dashboard </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showErrorDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="mb-4 text-h5 white--text error">
          There is a problem!
        </v-card-title>
        <v-card-text>
          <h4>An error has occurred.</h4>
          <p>
            <strong>{{ dialogErrorText }}</strong>
          </p>
          <p>Please refresh and try again.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            @click="showErrorDialog = false"
            class="mr-4"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDialog" persistent max-width="500">
      <v-card>
        <v-overlay
          :value="loading"
          color="#091437"
          opacity="0.85"
          absolute="absolute"
          class="text-center"
        >
          <h3 class="mb-4">Sending report...</h3>
          <v-progress-circular
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-overlay>

        <v-card-title class="mb-4 text-h5 white--text warning">
          Are you sure?
        </v-card-title>

        <v-card-text>
          <p>
            A custom report with the settings you specified will be sent to:
          </p>
          <ul class="mb-4">
            <li v-for="email in reportingEmails" :key="email.text">
              {{ email.text }}
            </li>
          </ul>

          <p>Are you sure you want to send the report?</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showConfirmDialog = false">
            Cancel
          </v-btn>

          <v-btn color="primary" text @click="handleConfirm"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import SearchSelectAdvisor from "@/components/SearchSelectAdvisor";

const initialSettings = function () {
  return {
    reportType: "Prospects",
    dateRangeType: "All Time",
    reportCustomDateRange: [],
    advisors: [],
    filters: [],
    emails: [],
  };
};

export default {
  components: {
    SearchSelectAdvisor,
  },
  data() {
    return {
      JWT: null,
      reportingEmails: [],
      valid: true,
      loading: false,
      showConfirmDialog: false,
      showSuccessDialog: false,
      showErrorDialog: false,
      dialogErrorText: "",
      reporting: new initialSettings(),
      reportTypeItems: ["Prospects"],
      reportDateRanges: [
        "All Time",
        "Year to Date",
        "Last Month",
        "Month to Date",
        "Custom",
      ],
      maxCustomDate: new Date().toISOString().slice(0, 10),
      minCustomDate: "2022-02-01",
      reportSelectedAdvisors: [],
      reportFiltersItems: [
        {
          text: "All Prospects",
          value: "all",
          disabled: false,
        },
        {
          text: "Partial and Completed Surveys",
          value: "partialAndCompleted",
          disabled: false,
        },
        {
          text: "Completed Surveys",
          value: "completed",
          disabled: false,
        },
        {
          text: "Prospects with Contact Info",
          value: "contactInfo",
          disabled: false,
        },
      ],
      rules: {
        required: [
          (v) => {
            return !!v || "This field is required.";
          },
        ],
        requiredArray: [
          (v) => {
            return v.length > 0 || "This field is required.";
          },
        ],
      },
    };
  },
  computed: {
    customDateRangeText() {
      return this.reporting.reportCustomDateRange.join(" ~ ");
    },
  },
  watch: {
    reportingEmails: {
      handler(val, prev) {
        if (val.length === prev.length) return;
        this.reportingEmails = val.map((v) => {
          if (typeof v === "string") {
            v = {
              text: v,
              color: "",
            };
          }
          return v;
        });
      },
    },
  },
  mounted() {
    Auth.currentSession().then((data) => {
      this.JWT = data.getAccessToken().getJwtToken();
    });
  },
  methods: {
    adjustFilterModel(selectedFilters, slug, include) {
      if (include) {
        return selectedFilters.filter((f) => {
          return f.value === slug;
        });
      } else {
        return selectedFilters.filter((f) => {
          return f.value !== slug;
        });
      }
    },
    handleFiltersChange(selectedFilters) {
      const reportFiltersItems = this.reportFiltersItems;
      const allProspects = selectedFilters.find((f) => f.value === "all");
      const partialAndCompletedSurveys = selectedFilters.find(
        (f) => f.value === "partialAndCompleted"
      );

      reportFiltersItems.forEach((f) => {
        f.disabled = false;
      });

      // If All Prospects not selected, then others must be, so disabled All Prospects
      // Else disable all others and set filters to only "all prospects"
      if (!allProspects) {
        if (selectedFilters.length) {
          reportFiltersItems.find((f) => {
            if (f.value === "all") {
              f.disabled = true;
            }
          });
        }

        // If Partial Completed selected, disable "Completed" option also
        if (partialAndCompletedSurveys) {
          reportFiltersItems.find((f) => {
            if (f.value === "completed") {
              f.disabled = true;
            }

            this.reporting.filters = this.adjustFilterModel(
              selectedFilters,
              "completed",
              false
            );
          });
        } else {
          reportFiltersItems.forEach((f) => {
            if (f.value !== "all") {
              f.disabled = false;
            }
          });
        }
      } else {
        // Disable all if All Prospects is selected
        reportFiltersItems.forEach((f) => {
          if (f.value !== "all") f.disabled = true;
        });

        this.reporting.filters = this.adjustFilterModel(
          selectedFilters,
          "all",
          true
        );
      }
    },
    handleDatePickerChange() {
      if (this.reporting.reportCustomDateRange.length < 2) {
        return "A date range must be selected.";
      } else {
        return true;
      }
    },
    handleRemoveEmail(item) {
      this.reportingEmails.splice(this.reportingEmails.indexOf(item), 1);
      this.reportingEmails = [...this.reportingEmails];
    },
    handleReset() {
      // this.reporting = new initialSettings();
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.reporting.reportType = "Prospects";
        this.reporting.dateRangeType = "All Time";
        this.reporting.reportCustomDateRange = [];
        this.reporting.advisors = [];
        this.reportFiltersItems.forEach((item) => {
          item.disabled = false;
        });
      });
    },
    async handleConfirm() {
      this.loading = true;
      try {
        const data = this.reporting;

        const emailsToSend = [];
        const filtersToSend = [];

        await Promise.all(
          this.reportSelectedAdvisors.map((e) => {
            console.log("HERE", e);
            data.advisors.push(e.id);
          })
        );

        await Promise.all(
          this.reportingEmails.map((e) => {
            emailsToSend.push(e.text);
          })
        );

        await Promise.all(
          this.reporting.filters.map((e) => {
            filtersToSend.push(e.value);
          })
        );

        data.emails = emailsToSend;
        data.filters = filtersToSend;
        console.log("CONFIRM DATA", data);
        const response = await fetch(
          process.env.VUE_APP_SCORE_API + "export_prospects_async",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.JWT}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          this.dialogErrorText = response;
          this.showErrorDialog = true;

          throw new Error(
            "Something went wrong exporting the report",
            response
          );
        } else {
          this.loading = false;
          this.showSuccessDialog = true;
        }
      } catch (error) {
        this.dialogErrorText = error.message;
        this.showErrorDialog = true;
        console.log("Error calling api to export report", error);
      } finally {
        this.showConfirmDialog = false;
        this.handleReset();
      }
    },
    handleEmailRules(value) {
      const emailPattern = new RegExp(/.+@.+\..+/);
      let validState = true;
      const validityCheck = [];

      if (value instanceof Array && value.length == 0) {
        return "Required.";
      }

      value.forEach((email) => {
        const isEmail = emailPattern.test(email.text);
        const isWhitelistDomain =
          /@plangap.com\s*$/.test(email.text) ||
          /@riskscore.us\s*$/.test(email.text) ||
          /@devmethods.com\s*$/.test(email.text);

        if (!isEmail || !isWhitelistDomain) {
          email.color = "error";
          validityCheck.push("invalid");
        } else {
          email.color = "success";
          validityCheck.push("valid");
        }
      });

      if (validityCheck.includes("invalid")) {
        validState =
          "Only valid emails and plangap.com and riskscore.us domains permitted!";
      } else {
        validState = true;
      }
      return validState;
    },
  },
};
</script>
