export const customSearchProspects = /* GraphQL */ `
  query CustomSearchProspects(
    $filter: SearchableProspectFilterInput
    $sort: [SearchableProspectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProspectAggregationInput]
  ) {
    searchProspects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        firstName
        lastName
        email
        phone
        advisorId
        scoreSurveyAnswers
        scoreSurveyComplete
        scoreSurveyResults
        scoreContactComplete
        createdAt
        updatedAt
        advisor {
          id
          firstName
          lastName
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
