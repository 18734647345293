export const customSearchAdvisors = /* GraphQL */ `
  query CustomSearchAdvisors(
    $filter: SearchableAdvisorFilterInput
    $sort: [SearchableAdvisorSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdvisorAggregationInput]
  ) {
    searchAdvisors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        companyName
        firstName
        lastName
        email
        phone
        createdAt
        updatedAt
        prospects {
          items {
            id
          }
        }
        vanityNames {
          items {
            id
            vanityName
          }
          nextToken
        }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
